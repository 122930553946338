import React, { useState, useEffect } from 'react'
import GulfFilingSidebar from '../GulfFilingSidebar'
import SidebarPageWrapper from '@coreComponents/sidebar-components/SidebarPageWrapper'
import Input from '@coreComponents/Input'
import SearchIcon from '@icons/SearchIcon'
import { FaRegFilePdf } from 'react-icons/fa6'
import { Link, useParams } from 'react-router-dom'
import { dashboardConfig } from 'pages/DashboadLayout/dashboardConfig'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { regions } from 'core/constants'
import { _getGCCFilingByExchange, _getGccExchangeByCountry } from '@api/public_filings'
import { useAppDispatch, useAppSelector } from '@redux/store'
import {
  clearExchanges,
  clearGCCFilings,
  clearSelectedGccFilingURL,
  setExchanges,
  setGCCFilings,
  setLoading,
  setSelectedExchange,
  setSelectedGccFilingURL
} from '@redux/publicFilings/publicFilingsSlice'
import {
  setChatBotSelectedDocuments,
  setFiling,
  setIsEmbedded,
  setIsPrivate,
  setLanguage,
  setOffering
} from '@redux/chatBot/chatBotSlice'
import { IoIosArrowBack } from 'react-icons/io'
import PDFIcon from '@icons/PDFIcon'
import { ShimmerDiv, ShimmerTable } from 'shimmer-effects-react'

function GulfFilings() {
  const { region } = useParams()
  const [countryName, setCountryName] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const dispatch = useAppDispatch()

  const exchanges = useAppSelector(state => state.publicFilings.exchanges)
  const selectedExchange = useAppSelector(state => state.publicFilings.selectedExchange)
  const gccFilings = useAppSelector(state => state.publicFilings.gcc_filings)
  const selectedIndustries = useAppSelector(state => state.publicFilings.selectedIndustries)
  const loading = useAppSelector(state => state.publicFilings.loading)

  useEffect(() => {
    const selectedRegion = regions.find(reg => reg.code === region)
    if (selectedRegion) {
      setCountryName(selectedRegion.name)

      dispatch(setLoading(true))
      _getGccExchangeByCountry(
        selectedRegion.name,
        data => {
          dispatch(setExchanges(data))
          dispatch(setSelectedExchange(data[0]))
          if (selectedRegion && data) {
            _getGCCFilingByExchange(
              { country: selectedRegion.name, exchange: data[0] },
              gcc_filings => {
                dispatch(setGCCFilings(gcc_filings))
                dispatch(setLoading(false))
              },
              err => {
                dispatch(setLoading(false))
              }
            )
          }
        },
        err => {
          dispatch(setLoading(false))
          console.error('Error fetching exchanges:', err)
        }
      )
    }
  }, [region, dispatch])

  useEffect(() => {
    if (countryName && selectedExchange) {
      dispatch(setLoading(true))
      _getGCCFilingByExchange(
        { country: countryName, exchange: selectedExchange },
        gcc_filings => {
          dispatch(setLoading(false))
          dispatch(setGCCFilings(gcc_filings))
        },
        err => {
          dispatch(setLoading(false))
          console.error('Error fetching GCC filings:', err)
        }
      )
    }
  }, [selectedExchange, countryName, dispatch])

  const [sliderRef, slider] = useKeenSlider({
    slides: {
      perView: 4,
      spacing: 8
    }
  })

  const years = [2018, 2019, 2020, 2021, 2022, 2023, 2024]
  const [selectedYear, setSelectedYear] = useState<number | null>(years[years.length - 1])

  const handleYearClick = (year: number) => {
    setSelectedYear(year)
  }

  const handlePrevClick = () => {
    slider.current?.prev()
  }

  const handleNextClick = () => {
    slider.current?.next()
  }

  useEffect(() => {
    slider.current?.moveToIdx(3)
  }, [slider])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  return (
    <SidebarPageWrapper
      renderSidebar={<GulfFilingSidebar />}
      className={`w-full overflow-y-auto ${dashboardConfig.paddingClassName}`}
    >
      <div className='mb-[26px] flex items-center justify-between'>
        <Input
          placeholder='Search company here'
          StartIcon={SearchIcon}
          boxClassName='border-none bg-chizl-black w-full max-w-[280px]'
          boxStyle={{ borderRadius: '5px' }}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className='relative flex items-center'>
          <button onClick={handlePrevClick} className='absolute left-0 z-10 p-2 text-white rounded-full -ml-6'>
            <IoIosArrowBack />
          </button>
          <div ref={sliderRef} className='keen-slider max-w-[260px] mx-[10px]'>
            {years.map(year => (
              <button
                key={year}
                className={`keen-slider__slide border border-primary-blue px-2 py-1 text-xs rounded ${
                  selectedYear === year ? 'bg-primary-blue text-white' : 'bg-transparent'
                }`}
                onClick={() => handleYearClick(year)}
              >
                {year}
              </button>
            ))}
          </div>
          <button
            onClick={handleNextClick}
            className='absolute right-0 z-10 p-2 text-white -mr-6'
            style={{ rotate: '180deg' }}
          >
            <IoIosArrowBack />
          </button>
        </div>
      </div>

      {loading ? (
        <div>
          {new Array(2).fill(0).map(() => (
            <div className='mb-10'>
              {new Array(5).fill(0).map(() => (
                <ShimmerDiv
                  mode='custom'
                  from='#323335'
                  via='#3f4347'
                  to='#323335'
                  className='mb-[4px]'
                  height={35}
                  width={'100%'}
                  border={0}
                />
              ))}
            </div>
          ))}
        </div>
      ) : (
        Object.keys(gccFilings)
          .filter(industry => selectedIndustries.includes(industry))
          .map(industry => {
            const filteredCompanies = Object.keys(gccFilings[industry]).filter(company =>
              company.toLowerCase().includes(searchTerm.toLowerCase())
            )

            // Only render if there are filtered companies
            if (filteredCompanies.length === 0) return null

            return (
              <div className='mb-[60px]' key={industry}>
                <h3 className='text-3xl font-semibold mb-[20px]'>{industry}</h3>
                <table className='table-auto w-full bg-light-black bg-opacity-60'>
                  <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '16%' }} /> {/* (100% - 20%) / 5 = 16% */}
                    <col style={{ width: '16%' }} />
                    <col style={{ width: '16%' }} />
                    <col style={{ width: '16%' }} />
                    <col style={{ width: '16%' }} />
                  </colgroup>
                  <thead className='bg-[#1A1B1C]'>
                    <tr>
                      <th className='text-left px-4 py-2 font-semibold'>Company</th>
                      <th className='text-left px-4 py-2 font-semibold'>Q1</th>
                      <th className='text-left px-4 py-2 font-semibold'>Q2</th>
                      <th className='text-left px-4 py-2 font-semibold'>Q3</th>
                      <th className='text-left px-4 py-2 font-semibold'>Q4</th>
                      <th className='text-left px-4 py-2 font-semibold'>Annual</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCompanies.map((company: any, idx: number) => (
                      <tr key={idx} className='border-b border-[#4A4A4A]'>
                        <td className='text-left px-4 py-2'>{company}</td>
                        {/* {['Q1', 'Q2', 'Q3', 'Q4', 'year'].map(
                          Q =>
                            gccFilings[industry][company][selectedYear ?? 2023] &&
                            Object.keys(gccFilings[industry][company][selectedYear ?? 2023]).includes(Q ?? '') &&
                            gccFilings[industry]?.[company]?.[selectedYear ?? '2023']?.[Q]?.is_embedded && (
                              <td
                                key={Q}
                                className='text-left px-4 py-2 font-semibold flex items-center justify-start gap-[5px]'
                              >
                                {Object.keys(gccFilings[industry][company][selectedYear ?? 2023][Q]).map(
                                  e =>
                                    e !== 'doc_id' &&
                                    e !== 'is_embedded' && (
                                      <Link
                                        to={
                                          gccFilings[industry][company][selectedYear ?? 2023][Q]?.is_embedded
                                            ? 'view'
                                            : '#'
                                        }
                                        onClick={() => {
                                          const filing =
                                            gccFilings[industry]?.[company]?.[selectedYear ?? 2023]?.[Q]?.[e]
                                          if (typeof filing === 'string') {
                                            dispatch(setSelectedGccFilingURL(filing))
                                            dispatch(setOffering(1))
                                            dispatch(setLanguage(e as 'En' | 'Ar'))
                                            dispatch(setFiling('gcc'))
                                            dispatch(setIsPrivate(false))
                                            dispatch(setIsEmbedded(true))
                                            dispatch(
                                              setChatBotSelectedDocuments([
                                                {
                                                  name: `${company} - ${Q === 'year' ? 'Annual' : Q}`,
                                                  id: gccFilings[industry][company][selectedYear ?? 2023][Q][
                                                    'doc_id'
                                                  ] as string
                                                }
                                              ])
                                            )

                                            console.log(gccFilings[industry]?.[company]?.[selectedYear ?? 2023]?.[Q])
                                          }
                                        }}
                                        className='inline-flex items-center mr-4'
                                      >
                                        <PDFIcon className='text-primary-blue w-4 mr-[2.5px]' />
                                        <span className='font-light'>{e}</span>
                                      </Link>
                                    )
                                )}
                              </td>
                            )
                        )} */}
                        {['Q1', 'Q2', 'Q3', 'Q4', 'year'].map(Q => (
                          <td key={Q} className='text-left px-4 py-2'>
                            <div className='flex'>
                              {Object.keys(
                                gccFilings[industry][company][selectedYear ?? 2023][Q]?.embedded_dict ?? {}
                              ).map(
                                lang =>
                                  Boolean(
                                    gccFilings[industry][company][selectedYear ?? 2023][Q]?.embedded_dict[lang]
                                  ) && (
                                    <Link
                                      to={
                                        gccFilings[industry][company][selectedYear ?? 2023][Q]?.embedded_dict[lang]
                                          ? 'view'
                                          : '#'
                                      }
                                      onClick={() => {
                                        const filing = gccFilings[industry]?.[company]?.[selectedYear ?? 2023]?.[Q]
                                        console.log(filing[lang])
                                        // if (typeof filing === 'string') {
                                        dispatch(setSelectedGccFilingURL(filing[lang]))
                                        dispatch(setOffering(1))
                                        dispatch(setLanguage(lang as 'En' | 'Ar'))
                                        dispatch(setFiling('gcc'))
                                        dispatch(setIsPrivate(false))
                                        dispatch(setIsEmbedded(true))
                                        dispatch(
                                          setChatBotSelectedDocuments([
                                            {
                                              name: `${company} - ${Q === 'year' ? 'Annual' : Q}`,
                                              id: gccFilings[industry][company][selectedYear ?? 2023][Q][
                                                'doc_id'
                                              ] as string
                                            }
                                          ])
                                        )
                                        // }
                                      }}
                                      className='inline-flex items-center mr-4'
                                    >
                                      <PDFIcon className='text-primary-blue w-4 mr-[2.5px]' />
                                      <span className='font-light'>{lang}</span>
                                    </Link>
                                  )
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          })
      )}
    </SidebarPageWrapper>
  )
}

export default GulfFilings
