// ** Axios Imports
import { AxiosError } from 'axios'

// ** API Imports
import { API } from '..'
import { ApiError } from 'core/types/api'

// ** Redux Imports
// import { store } from '@redux/store'

/**
 * Upload a research report document
 * @param file The document to be uploaded
 * @param callback Function to handle successful response
 * @param errCallback Function to handle error response
 */
export const _uploadUserDocument = (
  { file, language = 'En', is_private }: { file: File; language: 'Ar' | 'En'; is_private: boolean },
  callback?: (data: any) => void,
  errCallback?: (error: ApiError) => void
) => {
  const formData = new FormData()
  formData.append('file', file)

  API.post(`/api/v1/user_documents/upload?language=${language}&is_private=${is_private}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(res => callback && callback(res.data as any))
    .catch(err => errCallback && errCallback(err))
}
/**
 * Upload a research report document
 * @param file The document to be uploaded
 * @param callback Function to handle successful response
 * @param errCallback Function to handle error response
 */
export const _uploadResearchReportDocument = (
  { file, language = 'En' }: { file: File; language: 'Ar' | 'En' },
  callback?: (data: any) => void,
  errCallback?: (error: ApiError) => void
) => {
  const formData = new FormData()
  formData.append('file', file)

  API.post(`/api/v1/public_documents/upload?language=${language}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(res => callback && callback(res.data as any))
    .catch(err => errCallback && errCallback(err))
}
export const _uploadPrivateResearchReportDocument = (
  { file, language = 'En' }: { file: File; language: 'Ar' | 'En' },
  callback?: (data: any) => void,
  errCallback?: (error: ApiError) => void
) => {
  const formData = new FormData()
  formData.append('file', file)

  API.post(`/api/v1/private_documents/upload?language=${language}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(res => callback && callback(res.data as any))
    .catch(err => errCallback && errCallback(err))
}

export interface ResearchReportsDocumentInterface {
  user_id?: number
  id?: string
  document_name: string
  extension?: string
  path_to_s3?: string
  screenshot_s3_path?: string
  uploaded_at?: string
  isPrivate?: boolean
  language?: 'Ar' | 'En'
}

/**
 * Get all the research reports of a user
 * @param callback Function to handle successful response
 * @param errCallback Function to handle error response
 */
// export const _getResearchReports = (
//   callback?: (documents: ResearchReportsDocumentInterface[]) => void,
//   errCallback?: (error: AxiosError) => void
// ) => {
//   API.get(`/api/v1/public_documents/all`)
//     .then(res => callback && callback(res.data as ResearchReportsDocumentInterface[]))
//     .catch(err => errCallback && errCallback(err))
// }
// export const _getPrivateDocuments = (
//   callback?: (documents: ResearchReportsDocumentInterface[]) => void,
//   errCallback?: (error: AxiosError) => void
// ) => {
//   API.get(`/api/v1/private_documents/all`)
//     .then(res => callback && callback(res.data as ResearchReportsDocumentInterface[]))
//     .catch(err => errCallback && errCallback(err))
// }

export interface GetResearchReportDocumentResponseInterface {
  encoded_pdf: string
  extension: string
  name: string
  uploaded_at?: string
}
/**
 * Get all the companies for public filings
 * @param id The id of the document
 * @param callback Function to handle successful response
 * @param errCallback Function to handle error response
 */
// export const _getResearchReportDocument = (
//   id: string,
//   callback?: (response: GetResearchReportDocumentResponseInterface) => void,
//   errCallback?: (error: AxiosError) => void
// ) => {
//   API.get(`/api/v1/public_documents/?document_id=${id}`)
//     .then(res => callback && callback(res.data as GetResearchReportDocumentResponseInterface))
//     .catch(err => errCallback && errCallback(err))
// }

// export const _getPrivateResearchReportDocument = (
//   id: string,
//   callback?: (response: GetResearchReportDocumentResponseInterface) => void,
//   errCallback?: (error: AxiosError) => void
// ) => {
//   API.get(`/api/v1/private_documents/?document_id=${id}`)
//     .then(res => callback && callback(res.data as GetResearchReportDocumentResponseInterface))
//     .catch(err => errCallback && errCallback(err))
// }

export const _getMultiReportByID = (
  id: string,
  callback?: (response: GetResearchReportDocumentResponseInterface) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.get(`/api/v1/user_documents/?document_id=${id}`)
    .then(res => callback && callback(res.data as GetResearchReportDocumentResponseInterface))
    .catch(err => errCallback && errCallback(err))
}

export type MutliReportDocumentStatus = 'Processing' | 'Embedding' | 'Embedded' | null
export const _getDocumentStatuses = (
  callback?: (response: { id: string; status: MutliReportDocumentStatus }[]) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.get(`/api/v1/user_documents/embedding/status`)
    .then(res => callback && callback(res.data as any))
    .catch(err => errCallback && errCallback(err))
}

export const _deletePrivateDocument = (
  document_id: string,
  callback?: (response: {}) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.delete(`/api/v1/private_documents/delete/${document_id}`)
    .then(res => callback && callback(res.data as any))
    .catch(err => errCallback && errCallback(err))
}

export const _deletePublicDocument = (
  document_id: string,
  callback?: (response: {}) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.delete(`/api/v1/public_documents/delete/${document_id}`)
    .then(res => callback && callback(res.data as any))
    .catch(err => errCallback && errCallback(err))
}

/**
 * Get all the research reports of a user
 * @param callback Function to handle successful response
 * @param errCallback Function to handle error response
 */
export const _getUserDocuments = (
  callback?: (documents: ResearchReportsDocumentInterface[]) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.get(`/api/v1/user_documents/all`)
    .then(res => callback && callback(res.data as ResearchReportsDocumentInterface[]))
    .catch(err => errCallback && errCallback(err))
}
