// ** React Imports
import React, { useState } from 'react'

// ** Props Imports
import { ResearchReportFileProps } from './ResearchReportFileProps'

// ** Icons Imports
import PDFIcon from '@icons/PDFIcon'

// ! Delete this later
import { formatDateTime } from 'core/helpers/datetime/formatDateTime'
import ClickAwayListener from '@coreComponents/ClickAwayListener'
import { BsThreeDotsVertical, BsTrash, BsTrash3 } from 'react-icons/bs'
import { useAppDispatch, useAppSelector } from '@redux/store'
import { _deletePrivateDocument, _deletePublicDocument } from '@api/research_reports'
import { toast } from 'core/helpers/toast'
import Loader from '@coreComponents/Loader'
import { removeResearchReport } from '@redux/researchReports/researchReportsSlice'

function LoaderOverlay() {
  return <div className='absolute left-0 top-0 right-0 bottom-0 h-full w-full rounded-[12px]' style={{}}></div>
}

function ResearchReportFile({
  id,
  name,
  dateUploaded,
  extension,
  isSelected = false,
  isPrivate = false,
  imgUrl,
  ...rest
}: ResearchReportFileProps) {
  // ** Hooks
  const dispatch = useAppDispatch()

  // ** States
  const [open, setOpen] = useState<boolean>(false)
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const status = useAppSelector(state => state.researchReports.statuses?.find(doc => doc.id === id)?.status)

  // ** Handlers
  const handleClickAway = () => {
    if (open) {
      setOpen(false)
    }
  }

  const handleDelete = () => {
    if (!id) return
    setDeleteLoading(true)

    if (isPrivate) {
      _deletePrivateDocument(
        id,
        res => {
          toast.success('Document deleted')
          setDeleteLoading(false)
          dispatch(removeResearchReport(id))
        },
        err => {
          setDeleteLoading(false)
        }
      )
    } else {
      _deletePublicDocument(
        id,
        res => {
          toast.success('Document deleted')
          dispatch(removeResearchReport(id))
          setDeleteLoading(false)
        },
        err => {
          setDeleteLoading(false)
        }
      )
    }
  }

  if (status === null) return <></>
  return (
    <ClickAwayListener onClickAway={handleClickAway} className='relative'>
      {/* STATUS DISPLAY */}
      {status !== 'Embedded' && (
        <span className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-[15px] z-10 bg-secondary-blue px-[10px] py-[5px] rounded-full bg-opacity-55'>
          {status || 'Processing'}
        </span>
      )}

      <button
        {...rest}
        disabled={status !== 'Embedded'}
        onClick={status === 'Embedded' ? rest.onClick : undefined}
        onDoubleClick={status === 'Embedded' ? rest.onDoubleClick : undefined}
        className={`relative flex flex-col mt-[20px] items-center justify-between rounded-[12px] ${isSelected ? 'border-[1px] border-accent-orange shadow-accent-orange shadow-md' : 'border-[1px] border-chizl-black'} p-[8px] w-[225px] h-[197px] hover:shadow-sm hover:shadow-primary-blue transition-shadow ${rest.className} ${status !== 'Embedded' ? 'opacity-45 hover:shadow-none' : ''}`}
      >
        {status !== 'Embedded' && <LoaderOverlay />}
        {isPrivate && (
          <div className='absolute top-[-25px] left-0 bg-accent-orange bg-opacity-30 rounded-md px-[10px] text-sm text-accent-orange font-semibold'>
            {isPrivate && 'Private'}
          </div>
        )}
        {/* File Header */}
        <div className='flex items-center w-full gap-[7px] mb-[7px]'>
          <div>
            <PDFIcon width={20} height={20} color={isSelected ? 'var(--accent-orange)' : 'var(--primary-blue)'} />
          </div>
          <span className='text-ellipsis text-truncate text-sm'>{name}</span>

          <button
            onClick={e => {
              setOpen(true)
              e.stopPropagation()
            }}
            className='w-[23px] h-[23px] ml-auto'
          >
            <BsThreeDotsVertical size={17} />
          </button>
        </div>

        <img
          src={`https://chizl-public-docs.s3.amazonaws.com/${imgUrl}`}
          className='w-full h-[137px] border-none outline-none object-cover'
        />

        {dateUploaded && (
          <span className='text-[10px] text-background-cream'>Uploaded {formatDateTime(dateUploaded)}</span>
        )}

        {open && (
          <div className='absolute right-0 mt-6 w-[130px] bg-white border border-gray-300 rounded-lg shadow-lg z-50'>
            <button
              className='w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 rounded-lg'
              onClick={handleDelete}
            >
              <div className='flex items-center gap-2'>
                <BsTrash3 />
                Delete
                {deleteLoading && (
                  <Loader className='border-t-gray-500 border-gray-300 w-[11.5px] h-[11.5px] border-t-[2px] border-[2px]' />
                )}
              </div>
            </button>
          </div>
        )}
      </button>
    </ClickAwayListener>
  )
}

export default ResearchReportFile
