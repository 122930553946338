// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// ** Interface Imports
import { ChatBotMessageInterface } from '@api/chatbot'

export interface SelectedDocument {
  id: string | number
  name: string
  canClose?: boolean
  is_private?: boolean
  is_embedded?: boolean // New field for embedded documents
}

export interface PublicFilingsState {
  loading: boolean
  messages?: ChatBotMessageInterface[]
  offering?: number
  selectedDocuments?: SelectedDocument[]
  filing?: 'sec' | 'gcc'
  language?: 'Ar' | 'En'
  is_private?: boolean
  is_embedded?: boolean // New field for embedded documents
  document_ids?: string[]
}

const initialState: PublicFilingsState = {
  loading: false,
  messages: undefined,
  offering: undefined,
  selectedDocuments: undefined,
  filing: undefined,
  language: undefined,
  is_private: undefined,
  is_embedded: undefined, // Initialized as undefined
  document_ids: undefined
}

export const chatBotSlice = createSlice({
  name: 'chatBot',
  initialState,
  reducers: {
    // ---- SELECTED DOCUMENTS ----
    setChatBotSelectedDocuments: (state, action: PayloadAction<SelectedDocument[]>) => {
      state.selectedDocuments = action.payload
    },

    addChatBotSelectedDocument: (state, action: PayloadAction<SelectedDocument>) => {
      state.selectedDocuments = state.selectedDocuments
        ? [...state.selectedDocuments, action.payload]
        : [action.payload]
    },

    removeChatBotSelectedDocument: (state, action: PayloadAction<string | number>) => {
      state.selectedDocuments = state.selectedDocuments?.filter(doc => doc.id !== action.payload)
    },

    clearSelectedDocuments: state => {
      state.selectedDocuments = []
    },
    // ----------------------------

    setOffering: (state, action: PayloadAction<number>) => {
      state.offering = action.payload
    },

    setChatBotMessages: (state, action: PayloadAction<ChatBotMessageInterface[]>) => {
      state.messages = action.payload
    },

    setLastChatBotMessage: (state, action: PayloadAction<ChatBotMessageInterface>) => {
      state.messages = state.messages ? [...state.messages, action.payload] : [action.payload]
    },

    // ---- NEW FIELDS ----
    setFiling: (state, action: PayloadAction<'sec' | 'gcc'>) => {
      state.filing = action.payload
    },

    setLanguage: (state, action: PayloadAction<'Ar' | 'En'>) => {
      state.language = action.payload
    },

    setIsPrivate: (state, action: PayloadAction<boolean>) => {
      state.is_private = action.payload
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    setIsEmbedded: (state, action: PayloadAction<boolean>) => {
      state.is_embedded = action.payload
    },

    setDocumentIds: (state, action: PayloadAction<string[]>) => {
      state.document_ids = action.payload
    },

    clearFiling: state => {
      state.filing = undefined
    },

    clearLanguage: state => {
      state.language = undefined
    },

    clearIsPrivate: state => {
      state.is_private = undefined
    },

    clearIsEmbedded: state => {
      state.is_embedded = undefined
    },

    clearDocumentIds: state => {
      state.document_ids = undefined
    },

    /**
     * Clear all Public Filings related data
     */
    clearState: state => {
      state = initialState
    }
    // ----------------------------
  }
})

// Action creators are generated for each case reducer function
export const {
  setChatBotMessages,
  setLastChatBotMessage,
  setOffering,
  setChatBotSelectedDocuments,
  removeChatBotSelectedDocument,
  addChatBotSelectedDocument,
  clearSelectedDocuments,
  setFiling,
  setLanguage,
  setIsPrivate,
  setLoading,
  setIsEmbedded,
  setDocumentIds,
  clearFiling,
  clearLanguage,
  clearIsPrivate,
  clearIsEmbedded,
  clearDocumentIds,
  clearState
} = chatBotSlice.actions

export default chatBotSlice.reducer
